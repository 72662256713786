<template>
    <div class="plp-item__title-container" :title="headlineTitle">
        <span class="plp-item__title">{{
            noteForm && noteForm.note ? $t('Product.edit_note') : $t('Product.create_note')
        }}</span>
        <span class="plp-item__title plp-item__title-name">{{ product.name }}</span>
        <span class="plp-item__title plp-item__title-sep">,</span>
        <span class="plp-item__title plp-item__title-number">{{ product.modelNumberFrontend }}</span>
    </div>
    <AtomProductNoteForm
        ref="noteForm"
        :product-abstract-id="product.abstractId as string"
        @loaded="onNoteFormLoaded()"
    />
    <div class="controls">
        <AtomButton v-bind="modalButtons[0]" class="note-button-cancel" @click="buttonClicked(0)" />
        <AtomButton v-bind="modalButtons[1]" class="button" @click="buttonClicked(1)" />
    </div>
</template>

<script setup lang="ts">
import type { CatalogProduct } from '~/composables/types/api/searchDiscover/catalogSearch'

const { $t } = useNuxtApp()
const toasts = useToasts()
const props = defineProps<{
    product: CatalogProduct
}>()

const emit = defineEmits<{ (e: 'close'): void }>()

const noteForm = ref()
const noteOpen = ref(false)
const headlineTitle = computed(
    () => `${$t('Product.create_note')} ${props.product.name ?? ''}, ${props.product.modelNumberFrontend ?? ''}`
)

const closeNote = () => {
    noteOpen.value = false
    emit('close')
}

const onNoteFormLoaded = () => {
    const TIMEOUT_MS = 50

    setTimeout(() => noteForm.value.noteInput.focus(), TIMEOUT_MS)
}

const buttonClicked = async (index: number) => {
    switch (index) {
        case 0:
            closeNote()
            break
        case 1:
            // eslint-disable-next-line no-case-declarations
            const response = await noteForm.value.saveNote()

            if (typeof response !== 'boolean') {
                closeNote()

                return
            }

            if (!response) {
                return
            }

            if (noteForm.value?.note) {
                toasts.add({
                    type: 'success',
                    headline: $t('Product.note_saved'),
                    text: $t('Product.note_saved_text'),
                })
            } else {
                toasts.add({
                    type: 'success',
                    headline: $t('Product.note_deleted'),
                    text: $t('Product.note_deleted_text'),
                })
            }

            closeNote()
            break
        default:
            break
    }
}

const modalButtons: ModalButton[] = [
    {
        event: 'close',
        text: $t('General.cancel'),
        type: 'secondary',
    },
    {
        event: 'save',
        text: $t('General.save'),
        type: 'primary',
    },
]
</script>

<style lang="scss" scoped>
.plp-item {
    &__title-container {
        width: 100%;
        margin-bottom: sp(s);
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
    }

    &__title {
        @include helper-font-size(4);
        @include helper-font-weight(bold);
        @include helper-font-weight(medium);
        @include helper-font-size(3);
        @include helper-font-line-height(2);
        @include helper-color(text-title);
    }

    &__title-name {
        max-width: rem(300); /* Set to desired width */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: sp(xxs);
    }

    &__title-number {
        margin-right: sp(l);
        margin-left: sp(xxs);
    }

    &__title-sep {
        margin-left: 0;
    }

    &__created-at {
        margin-left: auto;
        margin-bottom: rem(4);
    }

    &__modal {
        :deep(.modal-box__custom-content) {
            overflow: inherit;
        }

        :deep(.modal-box__close) {
            display: none;
        }
    }
}

.controls {
    width: 100%;
    display: flex;
    gap: sp(s);
    padding-top: sp(m);
    padding-bottom: 1px;
    padding-left: 1px;

    .button {
        width: 100%;
    }
}
</style>
